import React, { useState, useEffect, useRef } from 'react';
import {
  DropdownMenuWrapper,
  DropdownMenuItemsWrapper,
  DropdownMenuItemWrapper
} from './dropdown.style';
import { debounce } from 'lodash';

const DropdownMenu = props => {
  const [menuState, setMenuState] = useState({
    show: false
  });

  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  });

  const handleToggle = item => {
    if (itemClicked && menuState.show) {
      itemClicked(item);
    }
    setMenuState(prevState => ({
      ...menuState,
      show: !prevState.show
    }));
  };

  const handleToggleDebounced = useRef(() => {
    setMenuState({ ...menuState, show: true });
  }).current;
  const handleCloseDebounced = useRef(
    debounce(() => {
      setMenuState({ ...menuState, show: false });
    }, 2000)
  ).current;

  const handleDocumentClick = () => {
    if (menuState.show) {
      handleToggle();
    }
  };

  const {
    content,
    dropdownItems,
    dropdownDirection,
    className,
    itemClicked,
    up = false
  } = props;

  return (
    <DropdownMenuWrapper
      onClick={e => e.stopPropagation()}
      onMouseLeave={e => {
        handleCloseDebounced();
      }}
      onMouseEnter={e => {
        handleCloseDebounced.cancel();
        handleToggleDebounced();
      }}
    >
      <span onClick={handleToggle}>{content}</span>
      {menuState.show && (
        <DropdownMenuItemsWrapper
          style={{ ...(up && { marginTop: -dropdownItems.length * 50 }) }}
          className={className}
          dropdownDirection={dropdownDirection}
        >
          {dropdownItems &&
            dropdownItems.map((item, index) => (
              <DropdownMenuItemWrapper
                key={index}
                onClick={() => {
                  handleToggle(item);
                }}
              >
                {item}
              </DropdownMenuItemWrapper>
            ))}
        </DropdownMenuItemsWrapper>
      )}
    </DropdownMenuWrapper>
  );
};

export default DropdownMenu;
