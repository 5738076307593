import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import FooterArea, { WidgetArea, CopyrightText } from './footer.style';
import { RowResp } from '../appClassic.style';
import { DownloadButtons } from '../QR';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        footer {
          logo {
            publicURL
          }
          menu {
            id
            link
            text
          }
          widgets {
            id
            url
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { widgets } = data.appClassicJson.footer;

  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea>
      <RowResp
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 50,
          paddingRight: 50,
          flexRwap: 'wrap',
        }}
      >
        <DownloadButtons width={120} />
        <WidgetArea>
          {widgets.map((item) => (
            <Box
              className='col'
              key={`footer-widget--key${item.id}`}
              onClick={() => {
                window.open(item.url);
              }}
            >
              <Image src={item.icon.publicURL} alt={'image'} />
            </Box>
          ))}
        </WidgetArea>
        <CopyrightText>
          Copyright {year} by{' '}
          <a
            target='_blank'
            href='https://appik.ch'
            // rel='noopener noreferrer'
          >
            Appik
          </a>{' '}
          <br />{' '}
          <a
            target='_blank'
            href='mailto:contact@apizoom.app'
            rel='noopener noreferrer'
          >
            contact@apizoom.app
          </a>{' '}
          <br />
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://docs.google.com/document/d/e/2PACX-1vS_qNSTi59p6mWwCkMR2Ex6rWqlYU8mNj04FwR_3-1suBmIWVnSJdCn1VgRfA8qrgt-kMQytEMITdOS/pub'
          >
            {' '}
            Terms & Conditions
          </a>
        </CopyrightText>
        {/* </MenuArea> */}
        {/* End of footer menu area */}
      </RowResp>
      {/* </Container> */}
    </FooterArea>
  );
};

export default Footer;
