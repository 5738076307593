const colors = {
  transparent: "transparent",
  footer: "#09142E",
  labelColor: "#767676",
  lightBorder: "#f1f4f6",
  inactiveField: "#F7F8FB",
  inactiveButton: "#b7dbdd",
  inactiveIcon: "#EBEBEB",
  primaryHover: "rgba(281,187,56,0.5)",
  secondary: "#FF716C",
  secondaryHover: "#112045",
  yellow: "#FFA740",
  yellowHover: "#F6C416",
  borderColor: "#E8EBF1",
  black: "#000000",
  white: "#ffffff",
  light: "#FAFBFF",
  gray: "#E4E4E4",
  primary: "#FBBB38",
  primaryLight: "rgba(254,232,189,1)",
  headingColor: "#0F2137",
  quoteText: "#343D48",
  menu: "#0D233E",
  textColor: "rgba(52, 61, 72, 0.8)",
  linkColor: "#2b9eff",
  shadow: "rgba(38, 78, 118, 0.1)",
  green: "#ABEDC6"
};

export default colors;
