import microsoft from 'common/src/assets/image/appClassic/microsoft.svg';
import agroscope from 'common/src/assets/image/appClassic/Agroscope.png';
import bannerImg from 'common/src/assets/image/appClassic/video_1.png';
import appleImg from 'common/src/assets/image/appClassic/download_apple.png';
import googleImg from 'common/src/assets/image/appClassic/download_google.png';
import previewImgOg from 'common/src/assets/image/appClassic/og_preview.png';

export const IMG_MICROSOFT = microsoft;
export const IMG_AGROSCOPE = agroscope;
export const IMG_BANNER = bannerImg;
export const IMG_DOWNLOAD_APPLE = appleImg;
export const IMG_DOWNLOAD_GOOGLE = googleImg;
export const IMG_PREVIEW_OG = previewImgOg;

export const marker_5_CHF = require('common/src/assets/image/appClassic/CHF-5-front.png');
export const marker_2_CHF = require('common/src/assets/image/appClassic/CHF-2-front.png');
export const marker_1_CHF = require('common/src/assets/image/appClassic/CHF-1-front.png');
export const marker_25_DOLL = require('common/src/assets/image/appClassic/US-25-Cent.png');
export const marker_50_DOLL = require('common/src/assets/image/appClassic/US-50-Cent.png');
export const marker_2_EUR = require('common/src/assets/image/appClassic/EUR-2-front.png');
export const marker_1_EUR = require('common/src/assets/image/appClassic/EUR-1-front.png');
export const marker_50_EUR = require('common/src/assets/image/appClassic/EUR-50-front.png');
export const custom_coin = require('common/src/assets/image/appClassic/custom_coin.png');
export const apizoom_example_small = require('common/src/assets/image/appClassic/apizoom_example_result_small.jpeg');

const APP_HINT_DOWNLOAD = require('common/src/assets/image/appClassic/app_hint_download.png');
export default APP_HINT_DOWNLOAD;
