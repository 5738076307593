import React, { Fragment, useState, useRef } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'reusecore/src/elements/UI/Logo';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import useOnClickOutside from 'common/src/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import { changeLocale, injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import DropdownMenu from 'common/src/components/Dropdown';
import { locales, isInLocales } from '../../../intl';
import { Row } from '../appClassic.style';
const Navbar = ({ intl, scan = false }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);
  let { logo, navMenu } = data.appClassicJson.navbar;
  navMenu = navMenu.map(item => ({
    ...item,
    label: intl.formatMessage({ id: item.label })
  }));

  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type, forceClose = false) => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: forceClose ? false : !state.mobileMenu
      });
    }
  };

  return (
    <NavbarWrapper className='navbar'>
      <Container className='row'>
        {scan && (
          <Row className='fullRowCenter'>
            <li className='homeCont'>
              <Link to={`/${intl.locale}/`}>
                {intl.formatMessage({ id: 'navBar.navMenu.home' })}
              </Link>
            </li>
            <ul className='fullRowCenter' style={{ marginBottom: 0 }}>
              <li>
                <a>{intl.formatMessage({ id: 'navBar.button' })}</a>
              </li>
            </ul>
            <DropDownWrapperIntl />
          </Row>
        )}
        {!scan && (
          <Fragment>
            <Logo
              className='logo noMargin'
              href='/'
              logoSrc={logo.publicURL}
              title='Apizoom'
            />
            {/* end of logo */}

            <MenuArea className={state.searchToggle ? 'active' : ''}>
              <ScrollSpyMenu
                className='menu'
                menuItems={navMenu}
                offset={-84}
              />
              {/* end of main menu */}

              <AnchorLink href='#trail' offset={84}>
                <Button
                  className='trail'
                  title={intl.formatMessage({ id: 'navBar.button' })}
                  uppercase={true}
                  onClick={_ => navigate(`/${intl.locale}/upload`)}
                />
              </AnchorLink>

              <DropDownWrapperIntl />

              <Button
                className='menubar'
                icon={
                  state.mobileMenu ? (
                    <Icon className='bar' icon={x} />
                  ) : (
                    <Fade>
                      <Icon className='close' icon={menu} />
                    </Fade>
                  )
                }
                color='#0F2137'
                variant='textButton'
                onClick={() => toggleHandler('menu')}
              />
            </MenuArea>
          </Fragment>
        )}
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <ScrollSpyMenu
            onUpdate={_ => toggleHandler('menu', true)}
            className='menu'
            menuItems={navMenu}
            offset={-84}
          />
          <Button
            title={<FormattedMessage id='navBar.button' />}
            onClick={() => {
              navigate(`/${intl.locale}/upload`);
            }}
          />
          <DropDownWrapperIntl
            mobile={true}
            callback={() => {
              toggleHandler('menu', true);
            }}
          />
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

const DropDownWrapper = ({ intl, mobile = false, callback }) => {
  return (
    <div
      className='dropDown'
      style={{
        ...(!mobile && { position: 'absolute', right: '3.2%' }),
        ...(mobile && { width: 'auto' })
      }}
    >
      <DropdownMenu
        up={mobile}
        dropdownItems={locales}
        content={intl.locale}
        itemClicked={item => {
          if (isInLocales(item)) changeLocale(item);
          if (callback) callback();
        }}
      />
    </div>
  );
};

const DropDownWrapperIntl = injectIntl(DropDownWrapper);

export default injectIntl(Navbar);
