import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { colorStyle } from '../../../../../common/src/theme/appclassic/customeVariant';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';

export const SectionWrapper = styled.section`
  background-color: ${themeGet('colors.primaryLight')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0;
  overflow: hidden;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }
`;

export const GetTheApp = styled(Text)`
  color: ${themeGet('colors.secondary')};
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  font-weight: 1000;
  letter-spacing: 2px;
  margin-bottom: 30px;
`;

export const ImageWrapper = styled(Image)`
  ${colorStyle};
  border-radius: 10px;
  width: ${({ width }) => (width ? `${width}px` : '150px')};
  @media screen and (max-width: 400px) {
    width: 120px;
  }
  height: auto;
  margin: 10px;
  object-fit: contain;
  border: ${({ border }) => (border ? themeGet(border) : 0)};
  border-color: ${themeGet('colors.primary', '#FBBB38')};
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: ${({ anim }) => (anim ? anim : '')};
  }
`;
