import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Row } from '../appClassic.style';

const FooterArea = styled.footer`
  padding: 40px 0 60px;
  margin-top: 75px;
  background-color: ${themeGet('colors.footer', '#09142E')};
  @media only screen and (max-width: 1366px) {
    padding-top: 40px;
    margin-top: 60px;
  }
  @media only screen and (max-width: 667px) {
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 25px;
  }
  .logo {
    img {
      width: 40px;
      height: auto;
    }
  }
`;

export const WidgetArea = styled(Row)`
  flex 1;
  min-width: 200px;
  justify-content: center;
  width: 100%;

  p {
    font-size: 12px;
    padding-top: 10px;
    color: white;
  }

  @media only screen and (max-width: 480px) {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .col {
    text-align: center;
    padding: 0 21px;

    img {
      display: inline-block;
      height: 31px;
      width: 150px;
      margin: 0px;
      transition: all .2s ease-in-out;
    &:hover {
      transform : scale(1.3);
    }
      @media only screen and (max-width: 1366px) {
        width: 100px;
        height: 30px;
        margin-bottom: 8px;
      }

    }
  }
`;

export const CopyrightText = styled.p`
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
  font-size: 14px;
  min-width: 200px;
  line-height: 18px;

  @media only screen and (max-width: 667px) {
    text-align: center;
    margin-top: 8px;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 12px;
  }

  a {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
    font-size: 14px;
    &:hover {
      color: ${themeGet('colors.primary')} !important;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 12px;
      text-align: center;
      line-height: 16px;
    }
  }
`;

export default FooterArea;
