import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { SectionWrapper, ImageWrapper, GetTheApp } from './QR.styles';
import { useStaticQuery, graphql } from 'gatsby';
import { Row } from '../appClassic.style';
import { IMG_DOWNLOAD_APPLE, IMG_DOWNLOAD_GOOGLE } from '../../../utils/images';
import { APIZOOM_APPLE_STORE, APIZOOM_GOOGLE_PLAY } from '../../../utils/const';

const QR = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        QR {
          image {
            publicURL
          }
        }
      }
    }
  `);
  const QRdata = data.appClassicJson.QR;
  const image = QRdata.image.publicURL;

  return (
    <SectionWrapper id='section-download'>
      <ImageWrapper
        border={'borders.3'}
        colors='primary'
        className='qr'
        src={image}
        alt={'qrcode'}
        anim={'scale(1.2)'}
      />
      <GetTheApp
        content={
          <p>
            {' '}
            {intl
              .formatMessage({ id: 'QR.get_the_app' })
              .toUpperCase()} <br />{' '}
            {intl.formatMessage({ id: 'QR.now' }).toUpperCase()}
          </p>
        }
      />
      <DownloadButtons />
    </SectionWrapper>
  );
};

export default injectIntl(QR);

export const DownloadButtons = ({ width = 150 }) => (
  <Row>
    <ImageWrapper
      anim={'scale(1.2)'}
      width={width}
      src={IMG_DOWNLOAD_APPLE}
      onClick={_ => window.open(APIZOOM_APPLE_STORE)}
      alt='apple_link'
    />
    <ImageWrapper
      anim={'scale(1.2)'}
      width={width}
      src={IMG_DOWNLOAD_GOOGLE}
      onClick={_ => window.open(APIZOOM_GOOGLE_PLAY)}
      alt='google_link'
    />
  </Row>
);
